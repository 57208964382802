import { v1 } from "uuid";
import { getAcceptedTermsAndConditions } from "./terms.service";

export type FacilityType = ('Metro'|'Train'|'Bus');

export type Facility = {
    id: string,
    name: string,
    type: FacilityType,
};

export type FacilityEntrance = {
    id: string,
    parkingEntranceLocation: string,
}

export type Occupancy = {
    total: string | null,
    transients: string | null,
};

export type Zone = {
    spots: string,
    zone_id: string,
    zone_name: string,
    parent_zone_id: string,
    occupancy: Occupancy,
};

export type FacilityInfo = {
    time: string,
    spots: string,
    parkID: string,
    MessageDate: string,
    facility_id: string,
    facility_name: string,
    tsn: string,
    tfnsw_facility_id: string,
    occupancy: Occupancy,
    zones: Zone[],
    parkingEntranceLocation: string | null,
};

export type ReportDetail = {
    Timestamp: string,
    FacilityId: string,
    Time: string,
    TotalSpot: number,
    Occupied: number,
};

export type Reports = {
    Last2HoursReport: ReportDetail[],
    LastFewMinutesReport: ReportDetail[],
};

const functionKey = "gNx6wBEe6Y4DbYckVepKO67am_BBGDyBuSFG6cgavIF3AzFuBsF-cA==";

const entranceLocation: FacilityEntrance[] = [{
    id: "26", // Tallawong P1 Car Park
    parkingEntranceLocation: "https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d829.9019119450703!2d150.9052778!3d-33.6932222!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzPCsDQxJzM1LjYiUyAxNTDCsDU0JzE5LjAiRQ!5e0!3m2!1sen!2sau!4v1696218363915!5m2!1sen!2sau",
},{
    id: "27", // Tallawong P2 Car Park
    parkingEntranceLocation: "https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d829.9043263119756!2d150.90441669999998!3d-33.6929722!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzPCsDQxJzM0LjciUyAxNTDCsDU0JzE1LjkiRQ!5e0!3m2!1sen!2sau!4v1696218447706!5m2!1sen!2sau",
},{
    id: "28", // Tallawong P3 Car Park
    parkingEntranceLocation: "https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d829.8968146817689!2d150.9038333!3d-33.69375!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzPCsDQxJzM3LjUiUyAxNTDCsDU0JzEzLjgiRQ!5e0!3m2!1sen!2sau!4v1696218503634!5m2!1sen!2sau",
},{
    id: "29", // Kellyville North Car Park
    parkingEntranceLocation: "https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d829.7285722512546!2d150.9344444!3d-33.7111667!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzPCsDQyJzQwLjIiUyAxNTDCsDU2JzA0LjAiRQ!5e0!3m2!1sen!2sau!4v1696218249883!5m2!1sen!2sau",
},{
    id: "30", // Kellyville South Car Park
    parkingEntranceLocation: "https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d829.6907279857996!2d150.93625!3d-33.715083299999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzPCsDQyJzU0LjMiUyAxNTDCsDU2JzEwLjUiRQ!5e0!3m2!1sen!2sau!4v1696218948993!5m2!1sen!2sau",
},{
    id: "31", // Bella Vista Car Park
    parkingEntranceLocation: "https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d829.5720682481731!2d150.9416944!3d-33.727361099999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzPCsDQzJzM4LjUiUyAxNTDCsDU2JzMwLjEiRQ!5e0!3m2!1sen!2sau!4v1696219020155!5m2!1sen!2sau",
},{
    id: "32", // Hills Showground Car Park
    parkingEntranceLocation: "https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d829.5704569011396!2d150.9854167!3d-33.72752779999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzPCsDQzJzM5LjEiUyAxNTDCsDU5JzA3LjUiRQ!5e0!3m2!1sen!2sau!4v1696224173287!5m2!1sen!2sau",
},{
    id: "33", // Cherrybrook Car Park
    parkingEntranceLocation: "https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d829.4791544552035!2d151.0335556!3d-33.7369722!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzPCsDQ0JzEzLjEiUyAxNTHCsDAyJzAwLjgiRQ!5e0!3m2!1sen!2sau!4v1696224283049!5m2!1sen!2sau",
},{
    id: "6", // Gordon Henry St North Car Park
    parkingEntranceLocation: "https://www.google.com/maps/embed?pb=!1m13!1m8!1m3!1d414.64267739849635!2d151.1545892159385!3d-33.75701127505464!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzPCsDQ1JzI1LjMiUyAxNTHCsDA5JzE2LjgiRQ!5e0!3m2!1sen!2sau!4v1730586791098!5m2!1sen!2sau",
},{
    id: "7", // Kiama Car Park
    parkingEntranceLocation: "https://www.google.com/maps/embed?pb=!1m13!1m8!1m3!1d410.1579116201653!2d150.85337110548787!3d-34.673303794126646!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzTCsDQwJzIzLjkiUyAxNTDCsDUxJzEyLjkiRQ!5e0!3m2!1sen!2sau!4v1730587044930!5m2!1sen!2sau",
},{
    id: "8", // Gosford Car Park
    parkingEntranceLocation: "https://www.google.com/maps/embed?pb=!1m13!1m8!1m3!1d416.2443991340643!2d151.34065013491818!3d-33.42441250537783!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzPCsDI1JzI3LjgiUyAxNTHCsDIwJzI3LjUiRQ!5e0!3m2!1sen!2sau!4v1730586860884!5m2!1sen!2sau",
},{
    id: "9", // Revesby Car Park
    parkingEntranceLocation: "https://www.google.com/maps/embed?pb=!1m13!1m8!1m3!1d413.7026444615385!2d151.01558529937094!3d-33.950870737908545!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzPCsDU3JzAzLjIiUyAxNTHCsDAwJzU2LjgiRQ!5e0!3m2!1sen!2sau!4v1730587398391!5m2!1sen!2sau",
},{
    id: "10", // Warriewood Car Park
    parkingEntranceLocation: "https://www.google.com/maps/embed?pb=!1m13!1m8!1m3!1d414.93826731046244!2d151.30242324536897!3d-33.69584984208751!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzPCsDQxJzQ1LjEiUyAxNTHCsDE4JzA5LjQiRQ!5e0!3m2!1sen!2sau!4v1730588238294!5m2!1sen!2sau",
},{
    id: "11", // Narrabeen Car Park
    parkingEntranceLocation: "https://www.google.com/maps/embed?pb=!1m13!1m8!1m3!1d414.8491286358322!2d151.2968546858443!3d-33.714304106885926!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzPCsDQyJzUxLjciUyAxNTHCsDE3JzQ5LjciRQ!5e0!3m2!1sen!2sau!4v1730588189799!5m2!1sen!2sau",
},{
    id: "12", // Mona Vale Car Park
    parkingEntranceLocation: "https://www.google.com/maps/embed?pb=!1m13!1m8!1m3!1d415.0268350909778!2d151.30611690559212!3d-33.67750493608837!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzPCsDQwJzM4LjkiUyAxNTHCsDE4JzIzLjQiRQ!5e0!3m2!1sen!2sau!4v1730588135672!5m2!1sen!2sau",
},{
    id: "13", // Dee Why Car Park
    parkingEntranceLocation: "https://www.google.com/maps/embed?pb=!1m13!1m8!1m3!1d414.6756914649774!2d151.28650591897954!3d-33.75018507931848!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzPCsDQ1JzAwLjciUyAxNTHCsDE3JzEyLjMiRQ!5e0!3m2!1sen!2sau!4v1730587959357!5m2!1sen!2sau",
},{
    id: "14", // West Ryde Car Park
    parkingEntranceLocation: "https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d828.7982389000989!2d151.09069440000002!3d-33.807333299999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzPCsDQ4JzI2LjQiUyAxNTHCsDA1JzI2LjUiRQ!5e0!3m2!1sen!2sau!4v1696228780653!5m2!1sen!2sau",
},{
    id: "15", // Sutherland East Parade Car Park
    parkingEntranceLocation: "https://www.google.com/maps/embed?pb=!1m13!1m8!1m3!1d413.3195749104455!2d151.05812630671113!3d-34.029590780070215!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzTCsDAxJzQ2LjUiUyAxNTHCsDAzJzMwLjEiRQ!5e0!3m2!1sen!2sau!4v1730587742735!5m2!1sen!2sau",
},{
    id: "16", // Leppington Car Park
    parkingEntranceLocation: "https://www.google.com/maps/embed?pb=!1m13!1m8!1m3!1d413.68826968042475!2d150.8068761582318!3d-33.95382762710618!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzPCsDU3JzEzLjkiUyAxNTDCsDQ4JzI1LjkiRQ!5e0!3m2!1sen!2sau!4v1730587175900!5m2!1sen!2sau",
},{
    id: "17", // Edmondson Park South Car Park
    parkingEntranceLocation: "https://www.google.com/maps/embed?pb=!1m13!1m8!1m3!1d413.6126642319519!2d150.85585212649352!3d-33.9693759216708!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzPCsDU4JzA5LjkiUyAxNTDCsDUxJzIyLjQiRQ!5e0!3m2!1sen!2sau!4v1730586737768!5m2!1sen!2sau",
},{
    id: "18", // St Marys Car Park
    parkingEntranceLocation: "https://www.google.com/maps/embed?pb=!1m13!1m8!1m3!1d414.6187147355219!2d150.7759579656067!3d-33.76196518371094!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzPCsDQ1JzQzLjAiUyAxNTDCsDQ2JzM0LjMiRQ!5e0!3m2!1sen!2sau!4v1730587692570!5m2!1sen!2sau",
},{
    id: "19", // Campbelltown Farrow Rd North Car Park 
    parkingEntranceLocation: "https://www.google.com/maps/embed?pb=!1m13!1m8!1m3!1d413.16082118804707!2d150.81497423313516!3d-34.062167446735344!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzTCsDAzJzQ0LjEiUyAxNTDCsDQ4JzU1LjEiRQ!5e0!3m2!1sen!2sau!4v1730586672661!5m2!1sen!2sau",
},{
    id: "20", // Campbelltown Hurley Steet South Car Park 
    parkingEntranceLocation: "https://www.google.com/maps/embed?pb=!1m13!1m8!1m3!1d413.14305916509795!2d150.81212572520067!3d-34.06581055481965!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzTCsDAzJzU2LjkiUyAxNTDCsDQ4JzQ0LjkiRQ!5e0!3m2!1sen!2sau!4v1730586471374!5m2!1sen!2sau",
},{
    id: "21", // Penrith Combewood At-Grade Car Park
    parkingEntranceLocation: "https://www.google.com/maps/embed?pb=!1m13!1m8!1m3!1d414.6720571170948!2d150.69781463939108!3d-33.750936599529176!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzPCsDQ1JzAzLjciUyAxNTDCsDQxJzUzLjYiRQ!5e0!3m2!1sen!2sau!4v1730587268336!5m2!1sen!2sau",
},{
    id: "22", // Penrith Combewood Multi-Level Car Park
    parkingEntranceLocation: "https://www.google.com/maps/embed?pb=!1m13!1m8!1m3!1d414.6840679026664!2d150.6950706183853!3d-33.74845292086251!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzPCsDQ0JzU0LjQiUyAxNTDCsDQxJzQyLjciRQ!5e0!3m2!1sen!2sau!4v1730587322113!5m2!1sen!2sau",
},{
    id: "23", // Warwick Farm Car Park
    parkingEntranceLocation: "https://www.google.com/maps/embed?pb=!1m13!1m8!1m3!1d413.8836787749696!2d150.93437092890537!3d-33.91361259797957!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzPCsDU0JzQ4LjkiUyAxNTDCsDU2JzA0LjQiRQ!5e0!3m2!1sen!2sau!4v1730588572210!5m2!1sen!2sau",
},{
    id: "24", // Schofields Car Park
    parkingEntranceLocation: "https://www.google.com/maps/embed?pb=!1m13!1m8!1m3!1d414.9051353710027!2d150.87181396892518!3d-33.70271014533574!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzPCsDQyJzA5LjkiUyAxNTDCsDUyJzE5LjciRQ!5e0!3m2!1sen!2sau!4v1730587470185!5m2!1sen!2sau",
},{
    id: "25", // Hornsby Jersey St Car Park
    parkingEntranceLocation: "https://www.google.com/maps/embed?pb=!1m13!1m8!1m3!1d414.9112088115138!2d151.09782036938105!3d-33.70145267022646!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzPCsDQyJzA1LjAiUyAxNTHCsDA1JzUzLjIiRQ!5e0!3m2!1sen!2sau!4v1730586931840!5m2!1sen!2sau",
},{
    id: "34", // Lindfield Village Green
    parkingEntranceLocation: "https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d829.1177502262784!2d151.1708611!3d-33.774333299999995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzPCsDQ2JzI3LjYiUyAxNTHCsDEwJzE1LjEiRQ!5e0!3m2!1sen!2sau!4v1730589337341!5m2!1sen!2sau",
},{
    id: "486", // Ashfield Car Park
    parkingEntranceLocation: "https://www.google.com/maps/embed?pb=!1m13!1m8!1m3!1d414.00766936210596!2d151.12609601048533!3d-33.888073653822914!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzPCsDUzJzE3LjIiUyAxNTHCsDA3JzM1LjAiRQ!5e0!3m2!1sen!2sau!4v1730586057413!5m2!1sen!2sau",
},{
    id: "487", // Kogarah Car Park
    parkingEntranceLocation: "https://www.google.com/maps/embed?pb=!1m13!1m8!1m3!1d413.64066309975857!2d151.13163442698362!3d-33.96361867222925!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzPCsDU3JzQ5LjQiUyAxNTHCsDA3JzU0LjgiRQ!5e0!3m2!1sen!2sau!4v1730587122717!5m2!1sen!2sau",
},{
    id: "488", // Seven Hills Car Park
    parkingEntranceLocation: "https://www.google.com/maps/embed?pb=!1m13!1m8!1m3!1d414.56535581098893!2d150.9364276833975!3d-33.77299401198605!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzPCsDQ2JzIyLjgiUyAxNTDCsDU2JzEyLjMiRQ!5e0!3m2!1sen!2sau!4v1730587549762!5m2!1sen!2sau",
},{
    id: "489", // Manly Vale Car Park
    parkingEntranceLocation: "https://www.google.com/maps/embed?pb=!1m13!1m8!1m3!1d414.49990239961556!2d151.26690039269442!3d-33.786518332406345!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzPCsDQ3JzExLjYiUyAxNTHCsDE2JzAxLjkiRQ!5e0!3m2!1sen!2sau!4v1730588034410!5m2!1sen!2sau",
},{
    id: "490", // Brookvale Car Park
    parkingEntranceLocation: "https://www.google.com/maps/embed?pb=!1m13!1m8!1m3!1d414.59325628354986!2d151.2691543621103!3d-33.76722762163814!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzPCsDQ2JzAyLjEiUyAxNTHCsDE2JzEwLjAiRQ!5e0!3m2!1sen!2sau!4v1730587916275!5m2!1sen!2sau",
}
];

export const getCarParkList = () => {
    return fetch('https://parkingspots.azurewebsites.net/api/ParkingSpots', {
        method: 'GET',
        headers: {
            'x-functions-key': functionKey
        },
    }).then(async(res) => {
        const result = await res.json();
        const entries = Object.entries<string>(result);
        return entries;    
    });
}

export const carParkTransform = (results: [string,string][] = []): Facility[] => {
    const metroCarPark = [
        '26', //'Tallawong P1 Car Park',
        '27', //'Tallawong P2 Car Park',
        '28', //'Tallawong P3 Car Park', 
        '29', //'Kellyville North Car Park',
        '30', //'Kellyville South Car Park',
        '31', //'Bella Vista Car Park',
        '32', //'Hills Showground Car Park',
        '33', //'Cherrybrook Car Park',
    ];

    const busCarPark = [
        '10', // Warriewood Car Park,
        '11', // Narrabeen Car Park,
        '12', // Mona Vale Car Park,
        '13', // Dee Why Car Park,
        '489', // Manly Vale Car Park,
        '490', // Brookvale Car Park,
    ];

    const dontShowCarpark = [
        '1', //'Tallawong Station Car Park (historical only)',
        '2', //'Kellyville Station Car Park (historical only)',
        '3', //'Bella Vista Station Car Park (historical only)',
        '4', //'Hills Showground Station Car Park (historical only)',
        '5', //'Cherrybrook Station Car Park (historical only)',
    ];

    const getCommuteType = (id: string) => {
        if (metroCarPark.includes(id)) {
            return 'Metro';
        }

        if (busCarPark.includes(id)) {
            return 'Bus';
        }

        return 'Train';
    };
    
    const facilities: Facility[] = results
            .filter((facility) => !dontShowCarpark.includes(facility[0]))
            .map((value) => {
                return {
                    id: value[0], 
                    name: formatFacilityName(value[1]),
                    type: getCommuteType(value[0]),
                };
            });

    return facilities;      
};

export const formatFacilityName = (value: string) => {
    const facilityName = value
        .replace('Station Car Park', '')
        .replace('Car Park', '')
        .replace('Park&Ride - ', '');

    return facilityName;
};

export const getFacilityInfo = (facilityId: string) => {
    return fetch(`https://parkingspots.azurewebsites.net/api/ParkingAvailability/${facilityId}`, {
        method: 'GET',
        headers: {
            'x-functions-key': functionKey
        },
    }).then(async(res) => {
        const jsonData = await res.json();
        const result = jsonData as FacilityInfo;
        result.parkingEntranceLocation = entranceLocation.find(l => l.id === facilityId)?.parkingEntranceLocation ?? null;
        return result;
    }, (reason) => {
        console.log(reason);
        return null;
    });
};

export const getAnnouncements = () => {
    return fetch(`https://parkingspots.azurewebsites.net/api/Announcements`, {
        method: 'GET',
        headers: {
            'x-functions-key': functionKey
        },
    }).then(async(res) => {
        const jsonData = await res.json();
        return jsonData;
    }, (reason) => {
        console.log(reason);
        return null;
    });
};

export const getReports = (facilityId: string) => {
    return fetch(`https://parkingspots.azurewebsites.net/api/ParkingAvailabilityPast2Hours/${facilityId}`, {
        method: 'GET',
        headers: {
            'x-functions-key': functionKey
        },
    }).then(async(res) => {
        const jsonData = await res.json();
        const result = jsonData as Reports;
        return result;
    }, (reason) => {
        console.log(reason);
        return null;
    });
};

export const getReportsForTheWeek = (facilityId: string, hour: string, minute: string) => {
    return fetch(`https://parkingspots.azurewebsites.net/api/ParkingAvailabilityThroughoutTheWeek/${facilityId}/${hour}/${minute}`, {
        method: 'GET',
        headers: {
            'x-functions-key': functionKey
        },
    }).then(async(res) => {
        const jsonData = await res.json();
        const result = jsonData as ReportDetail[];
        return result;
    }, (reason) => {
        console.log(reason);
        return null;
    });
};

export type Heartbeat = {
    userId: string;
    createdDate: Date;
    lastPulseDate: Date;
    acceptedTermsDate: Date;
};

export const pulse = () => {
    const heartbeatValue = localStorage.getItem("heartbeat");
    let heartbeat = {} as Heartbeat;
    if (heartbeatValue != null) {
        heartbeat = JSON.parse(heartbeatValue);
    }

    let updateHeartbeat = false;
    if (!!heartbeat.userId) {
        const dateNow = new Date().toLocaleDateString();
        const lastPulseDate = new Date(heartbeat.lastPulseDate).toLocaleDateString();

        if (dateNow !== lastPulseDate) {
            heartbeat.lastPulseDate = new Date();
            updateHeartbeat = true;
        }
    } else {
        const dateNow = new Date();
        heartbeat.userId = v1();
        heartbeat.createdDate = dateNow;
        heartbeat.lastPulseDate = dateNow;
        updateHeartbeat = true;
    }

    if (updateHeartbeat) {
        // Check if user has accepted terms
        const acceptedTermsAndConditions = getAcceptedTermsAndConditions();
        if (!!acceptedTermsAndConditions.dateAccepted) {
            heartbeat.acceptedTermsDate = acceptedTermsAndConditions.dateAccepted;
        }

        const heartbeatJson = JSON.stringify(heartbeat);
        localStorage.setItem("heartbeat", heartbeatJson);

        fetch(`https://parkingspots.azurewebsites.net/api/Heartbeat`, {
            method: 'POST',
            headers: {
                'x-functions-key': functionKey,
                'Content-Type': 'application/json',
            },
            body: heartbeatJson
        }).then(async(res) => {
            console.log("Pulse successful");
        }, (reason) => {
            console.log("Pulse failed: " + reason);
        });
    }
}