import { useState, createContext, useEffect, PropsWithChildren } from "react";

import {
  getCarParkList,
  getFacilityInfo,
  Facility,
  carParkTransform,
  FacilityInfo,
  getAnnouncements,
  getReports,
  Reports,
  getReportsForTheWeek,
  ReportDetail,
} from "./carpark.service";
import CurrentScreenSize, { ScreenSizeType } from "../utility/CurrentScreenSize";

export type CarParkContextType = {
  carParkLocations: Facility[];
  selectedCarPark: FacilityInfo | null;
  setSelectedCarPark: (facilityInfo: FacilityInfo | null) => void;
  getCarParkInfo: (facilityId: string) => Promise<FacilityInfo | null>;
  retrieveReports: (facilityId: string) => void;
  retrieveReportsForTheWeek: (facilityId: string, hour: string, minute: string) => void;
  reportData: Reports | null;
  onDemandReportData: ReportDetail[] | null;
  setReportData: (report: Reports | null) => void;
  setOnDemandReportData: (reportDetails: ReportDetail[] | null) => void;
  toggleMenu: (isOpen: boolean) => void;
  isMenuOpen: boolean;
  isLoading: boolean;
  error: string | null;
  chartError: string | null;
  listItemClicked: () => void;
  listItemClickCount: number;
  screenSize: ScreenSizeType;
  message: string;
};
export const CarParkContext = createContext<CarParkContextType>({} as CarParkContextType);
type CarParkProps = {};

export const CarParkContextProvider = (props: PropsWithChildren<CarParkProps>) => {
  const [carParkLocations, setCarParkLocations] = useState<Facility[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [chartError, setChartError] = useState<string | null>(null);
  const [selectedCarPark, setSelectedCarPark] = useState<FacilityInfo | null>(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [listItemClickCount, setListItemClickCount] = useState(0);
  const [message, setMessage] = useState("");
  const screenSize = CurrentScreenSize();
  const [reportData, setReportData] = useState<Reports | null>(null);
  const [onDemandReportIsRunning, setOnDemandReportIsRunning] = useState(false);
  const [onDemandReportData, setOnDemandReportData] = useState<ReportDetail[] | null>(null);


  let startedRetrieveCarParks: boolean = false;
  const retrieveCarParks = () => {
    if (!startedRetrieveCarParks) {
      startedRetrieveCarParks = true;
      setIsLoading(true);
      setCarParkLocations([]);

      getCarParkList()
        .then(carParkTransform)
        .then((results) => {
          setError(null);
          setIsLoading(false);
          setCarParkLocations(results);
          startedRetrieveCarParks = false;
        })
        .catch((err: Error) => {
          console.log(err);
          console.log(typeof(err));
          setCarParkLocations([]);
          setIsLoading(false);
          setError('Problem retrieving car park list. Try again later.');
          startedRetrieveCarParks = false;
        });
    }
  };

  const retrieveAnnouncements = () => {
    getAnnouncements()
      .then((result) => {
        setMessage(result);
      });
  };

  let startedRetrieveReports: boolean = false;
  const retrieveReports = (facilityId: string) => {
    if (!startedRetrieveReports) {
      startedRetrieveReports = true;
      setReportData(null);

      getReports(facilityId)
        .then((reports) => {
          setChartError(null);
          setReportData(reports);
          startedRetrieveReports = false;
        })
        .catch((err: Error) => {
          console.log(err);
          console.log(typeof(err));
          setReportData(null);
          setChartError('Problem retrieving chart data. Try again later.');
          startedRetrieveReports = false;
        });
    }
  };

  const retrieveReportsForTheWeek = (facilityId: string, hour: string, minute: string) => {
    if (!onDemandReportIsRunning)
    {
      setOnDemandReportIsRunning(true);
      getReportsForTheWeek(facilityId, hour, minute)
        .then((result) => {
          setChartError(null);
          setOnDemandReportData(result);
          setOnDemandReportIsRunning(false);
        })
        .catch((err: Error) => {
          console.log(err);
          console.log(typeof(err));
          setOnDemandReportData(null);
          setChartError('Problem retrieving chart data. Try again later.');
        });
    }
  };

  useEffect(() => {
    retrieveCarParks();
    retrieveAnnouncements();
  }, []);

  const getCarParkInfo = async(facilityId: string) => {
    try {
      const carParkInfo = await getFacilityInfo(facilityId);
      setSelectedCarPark(carParkInfo);
      setError(null);
      return carParkInfo;
    } catch (err) {
      console.log(err);
      setError('Problem retrieving the information. Try again later.');
      return null;
    }
  };

  const toggleMenu = (isOpen: boolean) => {
    setIsMenuOpen(isOpen);
  };

  const listItemClicked = () => {
    setListItemClickCount(listItemClickCount + 1);
  };

  return (
    <CarParkContext.Provider
      value={{
        carParkLocations,
        getCarParkInfo,
        retrieveReports,
        retrieveReportsForTheWeek,
        reportData,
        onDemandReportData,
        setReportData,
        setOnDemandReportData,
        selectedCarPark,
        setSelectedCarPark,
        toggleMenu,
        isMenuOpen,
        isLoading,
        error,
        chartError,
        listItemClicked,
        listItemClickCount,
        screenSize,
        message
      }}
    >
      {props.children}
    </CarParkContext.Provider>
  );
};
