import { useContext, useState } from 'react';
import { CarParkContext } from '../services/carpark.context';
import { Alert, Button, Dialog, DialogContent, DialogProps, DialogTitle, Divider, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import { FacilityInfo, formatFacilityName, ReportDetail } from '../services/carpark.service';
import { BarChart, BarItem, BarLabelContext } from '@mui/x-charts';
import CurrentScreenSize from '../utility/CurrentScreenSize';
import { BarChartOutlined, Close } from '@mui/icons-material';
import AutorenewIcon from '@mui/icons-material/Autorenew';

export interface SimpleDialogProps {
    open: boolean;
    selectedValue: FacilityInfo | null;
    onClose: () => void;
}

const ChartDialog = (props: SimpleDialogProps) => {
    const { selectedCarPark, retrieveReports, reportData, setReportData, chartError, retrieveReportsForTheWeek, onDemandReportData, setOnDemandReportData } = useContext(CarParkContext);
    const { onClose, selectedValue, open } = props;
    const [scroll] = useState<DialogProps['scroll']>('paper');
    const screenSize = CurrentScreenSize();
    const hasChartError = !!chartError;
    const [hourSelected, setHourSelected] = useState<string>("00");
    const [minuteSelected, setMinuteSelected] = useState<string>("00");
    const dayOfTheWeek = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];

    const handleClose = () => {
        onClose();
        setReportData(null);
        setOnDemandReportData(null);
    };

    if (open && reportData == null) {
        retrieveReports(selectedValue?.facility_id!);
    }

    const getXAxisData = (reportData: ReportDetail[]) => {
        return reportData.map(data => data.Time);
    };

    const getXAxisDataForTheWeekReport = (reportData: ReportDetail[]) => {
        return reportData.map(data => dayOfTheWeek[new Date(data.Timestamp).getDay()]);
    };

    const getYAxisData = (reportData: ReportDetail[]) => {
        const result = reportData.map(data => Math.round((data.Occupied / data.TotalSpot) * 100));
        return result;
    };

    const getBarLabelForLast2HoursReport = (item: BarItem, context: BarLabelContext) => {
        return reportData!.Last2HoursReport[item.dataIndex].Occupied.toString();
    }

    const getBarLabelForLastFewMinutesReport = (item: BarItem, context: BarLabelContext) => {
        return reportData!.LastFewMinutesReport[item.dataIndex].Occupied.toString();
    }

    const getBarLabelForTheWeekReport = (item: BarItem, context: BarLabelContext) => {
        return onDemandReportData![item.dataIndex].Occupied.toString();
    }


    const formatTwoDigits = (num: number): string => {
        return num < 10 ? `0${num}` : `${num}`;
    }

    let hours: string[] = [];
    for (let index = 0; index < 24; index++) {
        hours.push(formatTwoDigits(index));
    }

    let minutes: string[] = [];
    for (let index = 0; index < 60; index++) {
        minutes.push(formatTwoDigits(index));
    }

    

    const onHourSelected = (event: SelectChangeEvent) => {
        setHourSelected(event.target.value as string);
    }

    const onMinuteSelected = (event: SelectChangeEvent) => {
        setMinuteSelected(event.target.value as string);
    }

    const handleGenerateReport = () => {
        retrieveReportsForTheWeek(selectedValue?.facility_id!, hourSelected, minuteSelected);
    };

    return (
        <Dialog onClose={handleClose} open={open} scroll={scroll} maxWidth={'xl'} fullScreen={true}>
            <DialogTitle>{formatFacilityName(selectedCarPark?.facility_name ?? "")}</DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={(theme) => ({
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: theme.palette.grey[500],
                })}
                >
                <Close />
            </IconButton>
            <DialogContent dividers={scroll === 'paper'} style={{paddingLeft:0, paddingRight:0}}>
                {hasChartError && (<Alert severity="error">{chartError}</Alert>)}
                {!hasChartError && !reportData && (<AutorenewIcon fontSize="large" className="rotate" />)}
                {!hasChartError && !!reportData && (
                    <>
                        <BarChart
                            key="OccupancyLastFewMinutesBarChart"
                            xAxis={[{ data: getXAxisData(reportData.LastFewMinutesReport), scaleType: 'band'}]}
                            yAxis={[{
                                colorMap: {
                                    type:'ordinal', 
                                    colors: [],
                                    unknownColor: '#EE780C'
                                }}]}
                            series={[
                                {
                                    data: getYAxisData(reportData.LastFewMinutesReport),
                                    label: 'Occupancy (%) in last few minutes',
                                    color: '#EE780C'
                                },
                            ]}
                            width={screenSize.width - 20}
                            height={300}
                            grid={{ vertical: true, horizontal: true }}
                            barLabel={getBarLabelForLastFewMinutesReport}
                            borderRadius={5}
                        />
                        <BarChart
                            key="OccupancyLast2HoursBarChart"
                            xAxis={[{ data: getXAxisData(reportData.Last2HoursReport), scaleType: 'band'}]}
                            yAxis={[{
                                colorMap: {
                                    type:'ordinal', 
                                    colors: [],
                                    unknownColor: '#EE780C'
                                }}]}
                            series={[
                                {
                                    data: getYAxisData(reportData.Last2HoursReport),
                                    label: 'Occupancy (%) in last 2 hours',
                                    color: '#EE780C'
                                },
                            ]}
                            width={screenSize.width - 20}
                            height={300}
                            grid={{ vertical: true, horizontal: true }}
                            barLabel={getBarLabelForLast2HoursReport}
                            borderRadius={5}
                        />
                        <Divider />
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="subtitle1">On Demand Week Chart</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={2} 
                                    sx={{
                                        justifyContent: "flex-start",
                                        alignItems: "center"
                                    }}>
                                    <Grid item>
                                        <FormControl>
                                            <InputLabel id="hour-label">Hour</InputLabel>
                                            <Select
                                                labelId="hour-label"
                                                id="hour-select"
                                                value={hourSelected}
                                                label="Hour"
                                                onChange={onHourSelected}
                                            >
                                                {hours.map((hour) => <MenuItem key={"hour" + hour} value={hour}>{hour}</MenuItem>)}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item>
                                        <FormControl>
                                            <InputLabel id="minute-label">Minute</InputLabel>
                                            <Select
                                                labelId="minute-label"
                                                id="minute-select"
                                                value={minuteSelected}
                                                label="Minute"
                                                onChange={onMinuteSelected}
                                            >
                                                {minutes.map((minute) => <MenuItem key={"minute" + minute} value={minute}>{minute}</MenuItem>)}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item>
                                        <Button variant="contained" startIcon={<BarChartOutlined />} onClick={handleGenerateReport}>
                                            Generate
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                {!hasChartError && !!onDemandReportData && (
                                    <BarChart
                                        key="OccupancyLastWeekBarChart"
                                        xAxis={[{ data: getXAxisDataForTheWeekReport(onDemandReportData), scaleType: 'band'}]}
                                        yAxis={[{
                                            colorMap: {
                                                type:'ordinal', 
                                                colors: [],
                                                unknownColor: '#EE780C'
                                            }}]}
                                        series={[
                                            {
                                                data: getYAxisData(onDemandReportData),
                                                label: `Occupancy (%) in the week ${hourSelected}:${minuteSelected}`,
                                                color: '#EE780C'
                                            },
                                        ]}
                                        width={screenSize.width - 20}
                                        height={300}
                                        grid={{ vertical: true, horizontal: true }}
                                        barLabel={getBarLabelForTheWeekReport}
                                        borderRadius={5}
                                    />
                                )}
                            </Grid>
                        </Grid>
                    </>
                )}
            </DialogContent>
        </Dialog>
    );
};

export default ChartDialog;