import { useContext, useState } from 'react';
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { CarParkContext } from '../services/carpark.context';
import './AboutList.css';
import { Article, Coffee, Email, QuestionAnswer, Wysiwyg } from '@mui/icons-material';
import TermsAndConditionsDialog from './TermsAndConditionsDialog';

const AboutList = () => {  
  const { isMenuOpen } = useContext(CarParkContext);
  const [openTermsAndConditionsDialog, setOpenTermsAndConditionsDialog] = useState(false);

  let headingBackgroundColor = '#B0A797';

  const handleTermsAndConditionsDialogOpen = () => {
    setOpenTermsAndConditionsDialog(true);
  };

  const handleTermsAndConditionsDialogClose = () => {
    setOpenTermsAndConditionsDialog(false);
  };

  return (
    <>
      <List 
        subheader={<li/>}>
        <ListItem
          disablePadding
          sx={{
            display: 'block', backgroundColor: {backgroundColor: headingBackgroundColor}, color: 'white',
          }}
          key="Others"
        >
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: isMenuOpen ? 'initial' : 'center',
              px: 2.5,
            }}
            key="Others"
            disabled
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: isMenuOpen ? 3 : 'auto',
                justifyContent: 'center',
              }}
            >
              <Wysiwyg htmlColor="white" />
            </ListItemIcon>
            <ListItemText primary="Other Information" sx={{ opacity: isMenuOpen ? 1 : 0, justifyContent: 'left' }} />
          </ListItemButton>
        </ListItem>
        <ListItem key="buycoffee" disablePadding sx={{ display: 'block' }}>
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: isMenuOpen ? 'initial' : 'center',
              px: 2.5,
            }}
            key="buycoffee"
            href="https://www.buymeacoffee.com/d2dapps"
            target="_blank"
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: isMenuOpen ? 3 : 'auto',
                justifyContent: 'center',
              }}
            >
              <Coffee />
            </ListItemIcon>
            <ListItemText primary="Buy me a coffee" sx={{ opacity: isMenuOpen ? 1 : 0 }} />
          </ListItemButton>
        </ListItem>
        <ListItem key="feedback" disablePadding sx={{ display: 'block' }}>
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: isMenuOpen ? 'initial' : 'center',
              px: 2.5,
            }}
            key="feedback"
            href="https://forms.gle/yh5XqMfe5mQAs8WZ7"
            target="_blank"
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: isMenuOpen ? 3 : 'auto',
                justifyContent: 'center',
              }}
            >
              <QuestionAnswer />
            </ListItemIcon>
            <ListItemText primary="Feedback" sx={{ opacity: isMenuOpen ? 1 : 0 }} />
          </ListItemButton>
        </ListItem>
        <ListItem key="terms" disablePadding sx={{ display: 'block' }}>
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: isMenuOpen ? 'initial' : 'center',
              px: 2.5,
            }}
            key="terms"
            onClick={handleTermsAndConditionsDialogOpen}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: isMenuOpen ? 3 : 'auto',
                justifyContent: 'center',
              }}
            >
              <Article />
            </ListItemIcon>
            <ListItemText primary="Terms & Conditions" sx={{ opacity: isMenuOpen ? 1 : 0 }} />
          </ListItemButton>
        </ListItem>
        <ListItem key="contactus" disablePadding sx={{ display: 'block' }}>
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: isMenuOpen ? 'initial' : 'center',
              px: 2.5,
            }}
            key="contactus"
            href="mailto:sydneycommuterparking@gmail.com"
            target="_blank"
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: isMenuOpen ? 3 : 'auto',
                justifyContent: 'center',
              }}
            >
              <Email />
            </ListItemIcon>
            <ListItemText primary="Contact Us" sx={{ opacity: isMenuOpen ? 1 : 0 }} />
          </ListItemButton>
        </ListItem>
      </List>
      <TermsAndConditionsDialog
        open={openTermsAndConditionsDialog}
        onClose={handleTermsAndConditionsDialogClose}
      />
    </>
  );
};

export default AboutList;