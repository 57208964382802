import { useState, useEffect } from 'react';
import { Backdrop, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, FormControlLabel, FormGroup } from '@mui/material';
import { AcceptedTermCondition, getTermsAndConditionsToAccept, hasUserAcceptedAllTermsAndConditions, UserAcceptedTermsCondition, userHasAcceptedAllTermsAndConditions } from '../services/terms.service';
import CarParks from './CarParks';

const TermsAndConditions = () => {
    const [, setOpen] = useState(false);
    const [scroll] = useState<DialogProps['scroll']>('paper');
    const [showSplashScreen, setShowSplashScreen] = useState(true);
    const [userHasAcceptedTerms, setUserHasAcceptedTerms] = useState(hasUserAcceptedAllTermsAndConditions());
    
    const termsAndConditionsToAccept = getTermsAndConditionsToAccept();
    const emptyState: { [key: string]: AcceptedTermCondition } = {};
    const initialState = termsAndConditionsToAccept.reduce((acc, cur, i) => {
        acc[cur.code] = cur;
        return acc;
    }, emptyState);
    const [state, setState] = useState(initialState);

    const handleClose = () => {
        setOpen(false);
    };

    const handleAccept = () => {
        let userAcceptedTermsCondition: UserAcceptedTermsCondition = {
            dateAccepted: new Date(),
            acceptedTerms: Object.values(state)
        };

        userHasAcceptedAllTermsAndConditions(userAcceptedTermsCondition);
        setUserHasAcceptedTerms(true);
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const termBeingChanged = state[event.target.name];
        termBeingChanged.accepted = event.target.checked;

        setState({
          ...state,
          [event.target.name]: termBeingChanged,
        });
    };

    const allAccepted = (): boolean => {
        const acceptedTerms = termsAndConditionsToAccept.filter((termAncCondition) =>
            state[termAncCondition.code].accepted
        );

        return acceptedTerms.length == termsAndConditionsToAccept.length;
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowSplashScreen(false);
            clearTimeout(timer);
        }, 2000);
    });

    if (userHasAcceptedTerms) {
         return (<CarParks/>);
    } else {
        if (showSplashScreen) {
            return (
                <Backdrop
                    sx={{ color: '#fff', backgroundColor: '#EE780C', opacity: 0.1, zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={true}>
                    <img style={{maxWidth:'100%'}} src={require("../images/splash.png")} alt={"Sydney Commuter Parking"}/>
                </Backdrop>
            );
        } else {
            return (
                <>
                    <Backdrop
                        sx={{ color: '#fff', backgroundColor: '#EE780C', opacity: 1, zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={true}>
                    </Backdrop>
                    <Dialog
                        open={true}
                        onClose={handleClose}
                        scroll={scroll}
                        aria-labelledby="scroll-dialog-title"
                        aria-describedby="scroll-dialog-description"
                    >
                        <DialogTitle id="scroll-dialog-title">Terms and Conditions</DialogTitle>
                        <DialogContent dividers={scroll === 'paper'}>
                            <FormGroup>
                                {termsAndConditionsToAccept.map((termAndCondition, index) =>
                                    <FormControlLabel
                                        key={termAndCondition.code}
                                        control={<Checkbox checked={state[termAndCondition.code].accepted} onChange={handleChange} name={termAndCondition.code} />}
                                        label={termAndCondition.term}
                                        sx={(theme) => ({
                                            borderBottom: 1,
                                            borderColor: theme.palette.grey[500],
                                            paddingTop: 2,
                                            paddingBottom: 2,
                                        })}
                                    />
                                )}
                            </FormGroup>
                        </DialogContent>
                        <DialogActions>
                        <Button onClick={handleAccept} variant="contained" color="success" disabled={!allAccepted()}>Accept</Button>
                        </DialogActions>
                    </Dialog>
                </>
              );
        }
    }
};

export default TermsAndConditions;
