import { useState } from 'react';
import { Checkbox, Chip, Dialog, DialogContent, DialogProps, DialogTitle, FormControlLabel, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import { getAcceptedTermsAndConditions } from '../services/terms.service';

export interface SimpleDialogProps {
    open: boolean;
    onClose: () => void;
}

const TermsAndConditionsDialog = (props: SimpleDialogProps) => {
    const { onClose, open } = props;
    const [scroll] = useState<DialogProps['scroll']>('paper');

    const handleClose = () => {
        onClose();
    };

    var acceptedTermsAndConditions = getAcceptedTermsAndConditions();
    var dateAccepted = new Date(acceptedTermsAndConditions.dateAccepted);

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            scroll={scroll}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
        >
            <DialogTitle id="scroll-dialog-title">Terms and Conditions <Chip label={`Accepted: ${dateAccepted.toLocaleDateString()} ${dateAccepted.toLocaleTimeString()}`} /></DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={(theme) => ({
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: theme.palette.grey[500],
                })}
                >
                <Close />
            </IconButton>
            <DialogContent dividers={scroll === 'paper'}>
                {acceptedTermsAndConditions.acceptedTerms.map((acceptedTermAndCondition) =>
                    <FormControlLabel
                        key={acceptedTermAndCondition.code}
                        control={<Checkbox checked={acceptedTermAndCondition.accepted} disabled={true} name={acceptedTermAndCondition.code} />}
                        label={acceptedTermAndCondition.term}
                        sx={(theme) => ({
                            borderBottom: 1,
                            borderColor: theme.palette.grey[500],
                            paddingTop: 2,
                            paddingBottom: 2,
                        })}
                    />
                )}
            </DialogContent>
        </Dialog>
    );
};

export default TermsAndConditionsDialog;