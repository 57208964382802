import './App.css';
import { CarParkContextProvider } from './services/carpark.context';
import TermsAndConditions from './components/TermsAndConditions';
import { pulse } from './services/carpark.service';

function App() {
  pulse();

  return (
    <CarParkContextProvider>
      <TermsAndConditions/>
    </CarParkContextProvider>
  );
}

export default App;
