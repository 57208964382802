import { useContext } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Alert, Box, Card, CardActionArea, CardContent, CardMedia, Chip, Grid, Slider, TextField, Typography } from '@mui/material';
import { CarParkContext } from '../services/carpark.context';
import './FacilityList.css';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Occupancy, Zone } from '../services/carpark.service';

const FacilityDetail = () => {  
  const { selectedCarPark, toggleMenu, message } = useContext(CarParkContext);

  const getAvailable = (total: string, totalUsed: string): number => {
    return Number.parseInt(total) - Number.parseInt(totalUsed);
  };

  const occupancyMarks = [
    {
      value: 0,
      label: '0%',
    },
    {
      value: 25,
      label: '25%',
    },
    {
      value: 50,
      label: '50%',
    },
    {
      value: 75,
      label: '75%',
    },
    {
      value: 100,
      label: '100%',
    },
  ];

  function valuetext(value: number) {
    return `${value}%`;
  }

  const valueLabelFormat = (value: number) => {
    return `${value}%`;
  };

  const getOccupancyPercentage = (total: string, totalUsed: string): number => {
    return Math.round((Number.parseInt(totalUsed) / Number.parseInt(total)) * 100);
  };
  
  const getSliderColorStyle = (total: string, totalUsed: string) => {
    const color = getColorStyle(total, totalUsed);
    return { color };
  };

  const getColorStyle = (total: string, totalUsed: string) => {
    let color = 'green';
    const occupiedPerentage = getOccupancyPercentage(total, totalUsed);
    const available = getAvailable(total, totalUsed);
    if (available <= 1) {
      color = 'red';
    } else if (occupiedPerentage > 90) {
      color = 'orange';
    } 

    return color;
  };

  type ChipColor = "error" | "default" | "success" | "warning" | "primary" | "secondary" | "info" | undefined;

  const getChipColorStyle = (total: string, totalUsed: string): ChipColor => {
    let color: ChipColor = 'success';
    const occupiedPerentage = getOccupancyPercentage(total, totalUsed);
    const available = getAvailable(total, totalUsed);
    if (available <= 1) {
      color = 'error';
    } else if (occupiedPerentage > 90) {
      color = 'warning';
    } 

    return color;
  };

  const getChipLabel = (total: string, totalUsed: string): string => {
    let label = 'Available';
    const occupiedPerentage = getOccupancyPercentage(total, totalUsed);
    const available = getAvailable(total, totalUsed);
    if (available <= 1) {
      label = 'Full';
    } else if (occupiedPerentage > 90) {
      label = 'Almost Full';
    } 

    return label;
  };

  const getOccupancy = (occupancy: Occupancy): string => {
    return occupancy.total ?? occupancy.transients ?? '0';
  };

  const getValidZones = (zones: Zone[]): Zone[] => {
    return zones.filter(zone => !!zone.zone_id && zone.zone_id !== '0');
  };

  const getNumberOfValidZones = (zones: Zone[]): number => {
    return getValidZones(zones).length;
  };

  const handleWelcomeClick = async() => {
    toggleMenu(true);
  };

  return (
    <>
      {!!selectedCarPark && (
        <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
          <Grid container>
            <Grid item xs={12} md={getNumberOfValidZones(selectedCarPark.zones) > 1 ? 6 : 12} style={{padding: '10px', paddingTop: '0px'}}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h6">Parking Information</Typography>
                  <Chip style={{marginRight: '10px'}} 
                    label={getChipLabel(selectedCarPark.spots, getOccupancy(selectedCarPark.occupancy))} 
                    color={getChipColorStyle(selectedCarPark.spots, getOccupancy(selectedCarPark.occupancy))}
                  />
                  <Chip 
                    label={"Last Updated: " + new Date(Date.parse(selectedCarPark.MessageDate)).toLocaleString()} 
                    color="info"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Parking Station"
                    value={selectedCarPark.facility_name}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={8} sm={4}>
                  <TextField
                    fullWidth
                    label="Total Spots"
                    value={selectedCarPark.spots}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={8} sm={4}>
                  <TextField
                    fullWidth
                    label="Occupied"
                    value={getOccupancy(selectedCarPark.occupancy)}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={8} sm={4}>
                  <TextField
                    fullWidth
                    label="Available"
                    value={getAvailable(selectedCarPark.spots, getOccupancy(selectedCarPark.occupancy))}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography gutterBottom>
                    Parking Occupied
                  </Typography>
                  <br/>
                  <Slider
                    aria-label="facility-occupancy"
                    value={getOccupancyPercentage(selectedCarPark.spots, getOccupancy(selectedCarPark.occupancy))}
                    getAriaValueText={valuetext}
                    valueLabelFormat={valueLabelFormat}
                    step={1}
                    marks={occupancyMarks}
                    valueLabelDisplay="on"
                    style={getSliderColorStyle(selectedCarPark.spots, getOccupancy(selectedCarPark.occupancy))}
                  />
                </Grid>
              </Grid>
            </Grid>
            {getNumberOfValidZones(selectedCarPark.zones) > 1 &&
              <Grid item xs={12} md={6} style={{padding: '10px', paddingTop: '0px'}}>
                <Typography variant="h6">Parking Zones</Typography>
                <br/>
                {getValidZones(selectedCarPark.zones)
                  .map((zone) => {
                    return (
                      <Accordion key={`accordion_${zone.zone_id}`}>
                        <AccordionSummary style={{backgroundColor: getColorStyle(zone.spots, getOccupancy(zone.occupancy)), color: "white"}}
                          expandIcon={<ExpandMoreIcon />}
                        >
                          <Typography sx={{ marginRight: '10px', flexShrink: 0 }}>{zone.zone_name}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Chip
                            label={getChipLabel(zone.spots, getOccupancy(zone.occupancy))} 
                            color={getChipColorStyle(zone.spots, getOccupancy(zone.occupancy))}
                          />
                          <Grid container spacing={2} style={{padding: '30px'}}>
                            <Grid item xs={12}>
                              <TextField
                                fullWidth
                                label="Parking Zone"
                                value={zone.zone_name}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Grid>
                            <Grid item xs={8} sm={4}>
                              <TextField
                                fullWidth
                                label="Total Spots"
                                value={zone.spots}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Grid>
                            <Grid item xs={8} sm={4}>
                              <TextField
                                fullWidth
                                label="Occupied"
                                value={getOccupancy(zone.occupancy)}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Grid>
                            <Grid item xs={8} sm={4}>
                              <TextField
                                fullWidth
                                label="Available"
                                value={getAvailable(zone.spots, getOccupancy(zone.occupancy))}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <Typography gutterBottom>
                                Parking Occupied
                              </Typography>
                              <br/>
                              <Slider
                                aria-label="zone-occupancy"
                                value={getOccupancyPercentage(zone.spots, getOccupancy(zone.occupancy))}
                                getAriaValueText={valuetext}
                                valueLabelFormat={valueLabelFormat}
                                step={1}
                                marks={occupancyMarks}
                                valueLabelDisplay="on"
                                style={getSliderColorStyle(zone.spots, getOccupancy(zone.occupancy))}
                              />
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    );
                  })
                }
              </Grid>
            }
            <Grid item xs={12} md={12} style={{padding: '10px', paddingTop: '20px'}}>
              {selectedCarPark.parkingEntranceLocation && (
                <>
                  <Typography variant="h6">Parking Entrance</Typography>
                  <iframe title="Map" src={selectedCarPark.parkingEntranceLocation} width="100%" height="450" style={{border:0}} loading="lazy"></iframe>
                </>)
              }
              
            </Grid>
          </Grid>       
        </Box>
      )}
      {!selectedCarPark && (
        <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
          {!!message && (<><Alert severity="info">{message}</Alert><br/></>)}
          <Card sx={{ maxWidth: 345 }}>
            <CardActionArea onClick={(event) => handleWelcomeClick()}>
              <CardMedia
                component="img"
                height="140"
                image={require("../images/splash.png")}
                alt="Sydney Commuter Parking"
                sx={{
                  bgcolor: "#EE780C",
                  width: "100%",
                  height: "100%",
                  padding: "25px"
                }}
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Welcome
                </Typography>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  Click here to open the menu to begin your journey.
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Box>
      )}
    </>
  );
};

export default FacilityDetail;